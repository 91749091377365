@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../../../../assets/font/ProjectW/NeueHaasDisplay-Thin.woff2') format('woff2'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplay-Thin.woff') format('woff'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplayThin.ttf') format('ttf');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../../../../assets/font/ProjectW/NeueHaasDisplay-ThinItalic.woff2') format('woff2'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplay-ThinItalic.woff') format('woff'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplayThinItalic.ttf') format('ttf');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../../../../assets/font/ProjectW/NeueHaasDisplay-XThin.woff2') format('woff2'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplay-XThin.woff') format('woff'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplayXThin.ttf') format('ttf');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../../../../assets/font/ProjectW/NeueHaasDisplay-XThinItalic.woff2') format('woff2'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplay-XThinItalic.woff') format('woff'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplayXThinItalic.ttf') format('ttf');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../../../../assets/font/ProjectW/NeueHaasDisplay-Light.woff2') format('woff2'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplay-Light.woff') format('woff'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplayLight.ttf') format('ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../../../../assets/font/ProjectW/NeueHaasDisplay-LightItalic.woff2') format('woff2'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplay-LightItalic.woff') format('woff'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplayLightItalic.ttf') format('ttf');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../../../../assets/font/ProjectW/NeueHaasDisplay-Roman.woff') format('woff2'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplay-Roman.woff2') format('woff'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplayRoman.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../../../../assets/font/ProjectW/NeueHaasDisplay-RomanItalic.woff') format('woff2'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplay-RomanItalic.woff2') format('woff'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplayRomanItalic.ttf') format('ttf');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../../../../assets/font/ProjectW/NeueHaasDisplay-Mediu.woff2') format('woff2'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplay-Mediu.woff') format('woff'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplayMediu.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../../../../assets/font/ProjectW/NeueHaasDisplay-MediumItalic.woff2') format('woff2'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplay-MediumItalic.woff') format('woff'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplayMediumItalic.ttf') format('ttf');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../../../../assets/font/ProjectW/NeueHaasDisplay-Bold.woff2') format('woff2'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplay-Bold.woff') format('woff'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplayBold.ttf') format('ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../../../../assets/font/ProjectW/NeueHaasDisplay-BoldItalic.woff2') format('woff2'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplay-BoldItalic.woff') format('woff'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplayBoldItalic.ttf') format('ttf');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../../../../assets/font/ProjectW/NeueHaasDisplay-Black.woff2') format('woff2'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplay-Black.woff') format('woff'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplayBlack.ttf') format('ttf');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../../../../assets/font/ProjectW/NeueHaasDisplay-BlackItalic.woff2') format('woff2'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplay-BlackItalic.woff') format('woff'),
    url('../../../../assets/font/ProjectW/NeueHaasDisplayBlackItalic.ttf') format('ttf');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Machina';
  src: url('../../../../assets/font/ProjectW/NeueMachina-Medium.woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Machina';
  src: url('../../../../assets/font/ProjectW/NeueMachina-Bold.woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue-Machina-New';
  src: url('../../../../assets/font/ProjectW/NeueMachina-Medium.otf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue-Machina-New';
  src: url('../../../../assets/font/ProjectW/NeueMachina-Regular.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NHaasGroteskDSPro-55Rg';
  src: url('../../../../assets/font/ProjectW/NHaasGroteskDSPro-55Rg.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Canela-Trial';
  src: url('../../../../assets/font/ProjectW/Canela-Black-Trial.otf');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Canela-Trial';
  src: url('../../../../assets/font/ProjectW/Canela-BlackItalic-Trial.otf');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Canela-Trial';
  src: url('../../../../assets/font/ProjectW/Canela-Bold-Trial.otf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Canela-Trial';
  src: url('../../../../assets/font/ProjectW/Canela-BoldItalic-Trial.otf');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Canela-Trial';
  src: url('../../../../assets/font/ProjectW/Canela-Medium-Trial.otf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Canela-Trial';
  src: url('../../../../assets/font/ProjectW/Canela-MediumItalic-Trial.otf');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Canela-Trial';
  src: url('../../../../assets/font/ProjectW/Canela-Regular-Trial.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Canela-Trial';
  src: url('../../../../assets/font/ProjectW/Canela-RegularItalic-Trial.otf');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Canela-Trial';
  src: url('../../../../assets/font/ProjectW/Canela-Light-Trial.otf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Canela-Trial';
  src: url('../../../../assets/font/ProjectW/Canela-LightItalic-Trial.otf');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Canela-Trial';
  src: url('../../../../assets/font/ProjectW/Canela-Thin-Trial.otf');
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: 'Canela-Trial';
  src: url('../../../../assets/font/ProjectW/Canela-ThinItalic-Trial.otf');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../../../../assets/font/ProjectW/HelveticaNeue-CondensedBold.otf');
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../../../../assets/font/ProjectW/HelveticaNeue-Bold.otf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../../../../assets/font/ProjectW/helvetica-neue-regular.ttf');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../../../../assets/font/ProjectW/helvetica-neue-regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP-NeueMachhina';
  src: url('../../../../assets/font/ProjectW/PPNeueMachina-Bold.otf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
