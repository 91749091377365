.vjs-live {
  .vjs-age-restrictions {
    z-index: 999;
  }
  @media (min-width: 992px) {
    .vjs-age-restrictions {
      height: 100%;
    }
  }
}

.vjs--escondido {
  .vjs-age-restrictions {
    font-size: 5px;
    background: #000000;
    &__container {
      background-color: #fff;
      width: 90%;
      max-width: unset;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__wrap {
      padding: 10em 1em;
    }
    &__header {
      font-size: 5px;
      &--txtWarning {
        font-size: 10em;
        color: #000000;
        &:before {
          background-color: #eb2227;
        }
      }
      hr {
        background-color: #f26822;
        border: none;
        width: 55%;
        height: 2px;
      }
      &--txtWarnings {
        font-size: 7em;
        color: #000000;
      }
    }

    &__body {
      font-size: 5px;
      padding-top: 6em;
      &--txtDescription {
        color: #525252;
        text-decoration: none solid rgb(181, 181, 181);
        margin: 0 auto;
        font-size: 3.3em;
        line-height: 1.5em;
      }
    }
    &__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 5px;
      margin-top: 8.5em;
      .btn {
        color: #ffffff;
        font-size: 40px;
        padding: 25px 0;
        border-radius: 10px;
        max-width: unset;
      }
      .Confirm {
        margin-left: 18px;
        background-color: #eb2227;
        &:hover {
          background-color: #eb2227;
        }
      }
      .UnConfirm {
        text-transform: uppercase;
        background-color: #555555;
        &:hover {
          background-color: #555555;
        }
      }
    }
  }
}

.vjs-age-restrictions {
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #000000;
  position: absolute;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  flex-wrap: wrap;
  &__container {
    background-color: #212121;
    text-align: center;
    display: block;
    max-width: 500px;
    height: auto;
  }
  &__wrap {
    padding: 5em 2em;
  }
  &__header {
    font-size: 8px;
    &--txtWarning {
      color: #d60000;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 4.4em;
      position: relative;
      padding-bottom: 1rem;
      &:before {
        content: '';
        background-color: #d60000;
        width: 100%;
        height: 2px;
        position: absolute;
        left: 50%;
        bottom: 0;
        max-width: 55%;
        transform: translate(-50%, -50%);
      }
    }
    hr {
      background-color: #d60000;
      border: none;
      width: 55%;
      height: 2px;
    }
    &--txtWarnings {
      padding-top: 1rem;
      color: #d60000;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 2.2em;
    }
  }

  &__body {
    padding-top: 10px;
    font-size: 8px;
    &--txtDescription {
      color: #b5b5b5;
      text-decoration: none solid rgb(181, 181, 181);
      margin: 0 auto;
      font-size: 1.6em;
      line-height: 1.5em;
    }
  }
  &__footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    .btn {
      color: #ffffff;
      width: 45%;
      font-size: 1.4em;
      background: none;
      border: 1px solid #d6d6d6;
      text-align: center;
      cursor: pointer;
      padding: 0.75em 0;
      border-radius: 7px;
      transition: ease 0.5s;
    }
    .Confirm {
      margin-left: 40px;
      &:hover {
        background-color: #10911b;
      }
    }
    .UnConfirm:hover {
      background-color: #d60000;
    }
  }
}

.hide {
  display: none;
}
.disable {
  pointer-events: none;
  cursor: no-drop;
  i,
  span,
  div {
    color: rgb(83, 82, 82);
  }
}

@media (min-width: 576px) {
  .vjs-age-restrictions {
    font-size: 10px;
    &__wrap {
      padding: 2em 4em;
    }
    &__container {
      max-width: 615px;
    }
    &__header {
      font-size: 10px;
    }

    &__body {
      font-size: 10px;
    }
    &__footer {
      font-size: 10px;
    }
  }
}
@media (min-width: 992px) {
  .vjs-age-restrictions {
    height: 100%;
    font-size: 6px;
    &__container {
      max-width: 816px;
    }
    &__wrap {
      padding: 5em 5em;
    }
    &__header {
      font-size: 6px;
      &--txtWarning {
        font-size: 7.4em;
      }
      &--txtWarnings {
        font-size: 5.2em;
      }
    }

    &__body {
      font-size: 8px;
      &--txtDescription {
        font-size: 2em;
      }
    }

    &__footer {
      font-size: 7px;
      margin-top: 51px;
      .btn {
        width: 45%;
        max-width: 214px;
        font-size: 2em;
      }
    }
  }
  .vjs--escondido {
    .vjs-age-restrictions {
      font-size: 6px;
      &__wrap {
        padding: 10em 15%;
      }
      &__header {
        font-size: 6px;
      }

      &__body {
        font-size: 6px;
      }
      &__footer {
        font-size: 6px;
      }
    }
  }
}

@media (min-width: 1280px) {
  .vjs-age-restrictions {
    font-size: 7px;
    &__wrap {
      padding: 5em 10em;
    }
  }
  .vjs--escondido {
    .vjs-age-restrictions {
      font-size: 8px;
      &__container {
        height: 80%;
      }
    }
  }
}
@media (min-width: 1440px) {
  .vjs--escondido {
    .vjs-age-restrictions {
      font-size: 8px;
      &__container {
        max-width: 180.1em;
      }
      &__wrap {
        padding: 10em 15%;
      }
      &__header {
        font-size: 8px;
      }

      &__body {
        font-size: 8px;
      }
      &__footer {
        font-size: 8px;
      }
    }
  }

  .vjs-age-restrictions {
    font-size: 8px;
    &__header {
      font-size: 8px;
    }

    &__body {
      font-size: 8px;
    }
    &__footer {
      font-size: 8px;
    }
  }
}

@media (min-width: 1810px) {
  .vjs--escondido {
    .vjs-age-restrictions {
      &__container {
        height: 90%;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 10px;
        &__wrap {
          padding: 10em 25%;
        }
      }
      &__header {
        font-size: 10px;
      }
      &__body {
        font-size: 10px;
      }
      &__footer {
        font-size: 10px;
      }
    }
  }
  .vjs-age-restrictions {
    font-size: 10px;
    &__header {
      font-size: 10px;
    }

    &__body {
      font-size: 10px;
    }
    &__footer {
      font-size: 10px;
    }
  }
}
