body.vjs-player-page {
  min-height: calc(var(--vh, 1vh) * 100) !important;
  height: calc(var(--vh, 1vh) * 100) !important;
  // overflow: hidden;
  .videoAdUi {
    z-index: 9999;
  }
  #root,
  #root > .container {
    min-height: calc(var(--vh, 1vh) * 100) !important;
    height: calc(var(--vh, 1vh) * 100) !important;
  }
  &.vjs-player-fullmode {
    .header,
    .v-detailPage__recommand {
      display: none;
    }
    &.v-homePage,
    &.v-livePage {
      .header {
        display: unset;
      }
      .v-detailPage--loading {
        display: none !important;
      }
      #boxplayerWrapper {
        position: absolute;
        opacity: 1;
        top: 0;
        left: 0;
        .video-js .vjs-tech {
          object-fit: cover;
        }
        .video-js {
          .vjs-loading-spinner,
          .vjs-play-button-request {
            display: none;
          }
        }
        .vjs-error {
          display: none;
        }
      }
    }
    #boxplayerWrapper {
      width: 100%;
      height: 100%;
    }
  }
  cx-overlay[video-element-id='boxplayerWrapper'] {
    height: auto;
  }
}
#adblock {
  &-notice,
  &-wrapper {
    display: none;
  }
}
#boxplayerWrapper {
  z-index: 1;
  overflow-x: hidden;
  width: 75%;
  // height: calc(var(--vh, 1vh) * 100);
  &.vjs-open {
    .vjs-top-navigation {
      display: block;
    }
  }
  .vjs-ads-blocker {
    height: 99%;
    background: #ffffff;
    position: absolute;
    z-index: 999;
    font-size: 1em;
    width: 100%;
    top: 0;
    &--container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    &--title {
      font-size: 1.875em;
      font-weight: 600;
      color: #283237;
    }
    &--des {
      color: #495963;
      font-size: 1.25em;
    }
    &--btn {
      background: #2574d4;
      color: #ffffff;
      padding: 1rem 2rem;
      border-radius: 0.5rem;
      margin-top: 1rem;
      border: none;
      font-weight: 600;
    }
  }
}
body.vjs-player-page {
  #onesignal-bell-container {
    display: none;
  }
}

body {
  .vjs-open {
    .vjs-black-out-display,
    video,
    .vjs-control-bar,
    .vjs-epg-button {
      display: block;
      visibility: hidden !important;
      opacity: 0 !important;
      transition: opacity 0.25s;
    }
    .vjs-error-display {
      display: none;
    }
  }
  .vjs-popup-upgrade,
  .vjs-hide-error {
    .vjs-error-display {
      display: none;
    }
  }
  .video-js {
    width: 100%;
    height: 100%;
  }
  .vjs-menu-item.vjs-captions-menu-item {
    display: none;
  }

  /*
  Player Skin Designer for Video.js
  http://videojs.com

  To customize the player skin edit
  the CSS below. Click "details"
  below to add comments or questions.
  This file uses some SCSS. Learn more
  at http://sass-lang.com/guide)

  This designer can be linked to at:
  https://codepen.io/heff/pen/EarCt/left/?editors=010
*/

  // The following are SCSS variables to automate some of the values.
  // But don't feel limited by them. Change/replace whatever you want.
  // The color of icons, text, and the big play button border.
  // Try changing to #0f0
  $primary-foreground-color: #cacaca; // #fff default
  $secondary-foreground-color: #2574d4;
  // The default color of control backgrounds is mostly black but with a little
  // bit of blue so it can still be seen on all-black video frames, which are common.
  // Try changing to #900
  $primary-background-color: #262626; // #2B333F default
  // Try changing to true
  $center-big-play-button: true; // true default
  $big-play-button: false; // hide big play button
  $controls-margin: 2em;
  // Make a slightly lighter version of the main background
  // for the slider background.
  $slider-bg-color: lighten($primary-background-color, 3%);
  $progress-position: 1em;
  // @font-face {
  //   font-family: nf-icon;
  //   src: url(https://assets.nflxext.com/ffe/siteui/fonts/nf-icon-v1-86.eot);
  //   src: url(https://assets.nflxext.com/ffe/siteui/fonts/nf-icon-v1-86.eot?#iefix) format('embedded-opentype'), url(https://assets.nflxext.com/ffe/siteui/fonts/nf-icon-v1-86.woff) format('woff'), url(https://assets.nflxext.com/ffe/siteui/fonts/nf-icon-v1-86.ttf) format('truetype'), url(https://assets.nflxext.com/ffe/siteui/fonts/nf-icon-v1-86.svg#nf-icon-v1-86) format('svg');
  //   font-weight: 400;
  //   font-style: normal
  // }
  .vjs-button > .vjs-icon-placeholder:before {
    font-size: 1.5em;
  }
  .vjs-text-track-display > div > div {
    // transition: all 0.5s;
  }
  .vjs-paused,
  .vjs-user-active {
    &:not(.vjs--projectw) {
      .vjs-text-track-display {
        bottom: 0 !important;
        > div > div {
          transform: translateY(-100%) !important;
          top: calc(100% - var(--cb)) !important;
        }
      }
    }
  }
  .video-js {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    .vjs-drm-error {
      z-index: 9;
      background: black;
      .vjs-modal-dialog-content {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      .vjs-close-button {
        display: none;
      }
    }
  }
  .vjs-space-control-bar {
    flex-grow: 1 !important;
  }
  .ima-ad-container {
    z-index: 999 !important;
  }
  .vjs-title-control-bar {
    display: none !important;
    ~ .vjs-space-control-bar {
      display: none;
    }
  }

  .video-js {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    &.vjs-user-inactive {
      .vjs--logo__chanel {
        bottom: 20px;
      }
    }
    .ima-ad-container {
      z-index: 999 !important;
    }
    .vjs--logo__chanel {
      position: absolute;
      left: 60px;
      bottom: 110px;
      display: none;
      transition: 0.5s ease;
    }
    &.vjs-ad-playing {
      .vjs-report,
      .vjs-btn-share {
        z-index: -1;
      }
    }
    &.vjs-report-open {
      &.vjs-ad-playing {
        .v-reportContents,
        .vjs-report,
        .vjs-btn-share {
          z-index: -1 !important;
        }
      }
    }
    &.vjs-curated-live,
    &.vjs-disable-offlive {
      &.vjs-live {
        &.vjs-liveui {
          &.vjs-error {
            .vjs-play-control {
              pointer-events: none;
              color: gray;
            }
          }
          &:not(.vjs-simulated-live-event) .vjs-progress-control {
            pointer-events: none;
          }
          &:not(.vjs-simulated-live-event) .vjs-progress-control {
            .vjs-load-progress {
              width: 100% !important;
              div {
                width: 0% !important;
              }
            }
          }
          .vjs-fullscreen-control {
            position: relative;
          }
          .vjs-next-epg,
          .vjs-prev-epg {
            display: none;
          }
          .vjs-control-bar {
            button[title='Replay'] {
              pointer-events: none;
              color: gray;
            }
          }
          .vjs-seek-to-live-control {
            pointer-events: none;
          }
          &.vjs-default-skin.vjs-ended {
            .vjs-play-control {
              .vjs-icon-placeholder:before {
                content: '\f103';
              }
            }
          }
          .vjs-seek-to-live-control.vjs-control.vjs-at-live-edge .vjs-icon-placeholder,
          .vjs-seek-to-live-control .vjs-icon-placeholder {
            color: red !important;
          }
        }
      }
      .vjs-next-epg,
      .vjs-prev-epg {
        &.vjs-button-next-open {
          display: block;
        }
        display: none;
      }

      .vjs-cooldown-display {
        display: none;
        &.vjs-cooldown-open {
          display: block !important;
        }
      }
      &.vjs-none-linkplay {
        .vjs-control-bar {
          display: flex;
        }
        .vjs-error-display {
          display: none !important;
        }
        .vjs-epg-display {
          display: block !important;
        }
      }
    }
    .vjs-recommend-display {
      display: none;
      &.vjs-recommend-open {
        display: block !important;
        height: 100%;
      }
    }
    .vjs-fullscreen-control {
      position: absolute;
      right: 0;
      float: right;
    }
    // .vjs-mouse-display{
    //   .vjs-time-tooltip {
    //     background: none!important;
    //     color: #fff;
    //     font-weight: 400;
    //     font-size: 1rem!important;
    //     top: -2.2rem!important;
    //     &::before {
    //       display: none;
    //     }
    //   }
    //   .vjs-scubber-thumbnail {
    //     position: absolute;
    //     bottom: 25px;
    //     left: 0;
    //     transform: translateX(-50%);
    //     transition: all 0.5s;
    //     width: 120px;
    //     .vjs-thumbnail-src {
    //       width: 100%;
    //     }
    //   }
    // }
    /* The base font size controls the size of everything, not just text.
     All dimensions use em-based sizes so that the scale along with the font size.
     Try increasing it to 15px and see what happens. */
    font-size: 16px;
    /* The main font color changes the ICON COLORS as well as the text */
    color: $primary-foreground-color;
    :focus {
      outline: none;
    }
    .vjs-control-bar {
      .vjs-subs-caps-button {
        display: block;
        &.vjs-not-subtitle {
          display: none;
        }
      }
    }
    .vjs-list-eps {
      z-index: 999;
      .vjs-list-title-eps {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 2.5rem;
        font-size: 1rem;
        line-height: 1.25rem;
        -webkit-box-orient: vertical;
        text-align: left;
        word-break: break-word;
        width: 72%;
      }
      .vjs-list-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .vjs-list-vipbage {
          display: flex;
          justify-content: flex-end;
          width: 25%;
          align-items: center;
        }
      }
    }
    .vjs-epg-button,
    .vjs-epg-button:focus {
      background-color: #dadada;
      color: #9b9b9b;
      border-radius: 5px;
    }
    .vjs-epg-button {
      &--text {
        display: none;
      }
    }
    &:not(.vjs-episodes) {
      .vjs-list-eps,
      .vjs-next,
      .vjs-prev {
        display: none !important;
      }
    }
    &.vjs-curated-live,
    &.vjs-disable-offlive {
      .vjs-control-bar {
        .vjs-subs-caps-button {
          &.vjs-not-subtitle {
            display: none;
            pointer-events: none;
          }
        }
      }
    }
    &.vjs-live {
      .vjs-list-eps,
      .vjs-play-progress::before,
      .vjs-time-tooltip,
      .vjs-next,
      .vjs-prev,
      .vjs-current-time,
      .vjs-mouse-display,
      button.vjs-subs-caps-button.vjs-not-subtitle,
      .vjs-seek-next,
      .vjs-seek-prev {
        display: none;
      }
      button.vjs-subs-caps-button.vjs-not-subtitle,
      .vjs-seek-next,
      .vjs-seek-prev,
      .vjs-playing {
        pointer-events: none;
        color: gray;
      }
      .vjs-progress-control {
        padding-right: 0;
      }
    }
    &.vjs-report-open {
      .vjs-report-display {
        display: block;
      }
      .vjs-top-navigation {
        display: none !important;
      }
    }
    .vjs-btn-share {
      transition: right 1s;
      position: absolute;
      top: 2%;
      right: calc(2% + 4rem);
      display: none;
      flex-direction: column;
      z-index: 0;
      align-items: center;
      i {
        width: 30px;
        height: 30px;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: #000000;
        margin-bottom: 0.5rem;
        font-size: 1rem;
      }
      span {
        color: #fff;
        font-size: 14px;
      }
    }
    .vjs-report {
      transition: right 1s;
      position: absolute;
      top: 2%;
      right: 2%;
      display: none;
      flex-direction: column;
      z-index: 999;
      align-items: center;
      &-display {
        display: none;
      }
      i {
        width: 30px;
        height: 30px;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: #000000;
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
      }
      span {
        color: #fff;
        font-size: 14px;
      }
      &.disable {
        i {
          background: #7d7d7d;
        }
        span {
          color: #7d7d7d;
        }
      }
    }
  }
  .vjs-epg-open {
    .vjs-ad-playing {
      .vjs-epg-display {
        display: none;
      }
    }
    .vjs-epg-button,
    .vjs-epg-button:focus {
      background-color: transparent;
    }
  }
  .video-js .vjs-top-policy {
    right: 0;
    position: absolute;
    display: visible;
    z-index: 9999;
    width: 100%;
    background: #1c1c1c;
    top: 2.975rem;
    padding: 1.25rem 0;
    transition: all 1s;
    transform: translateX(200%);
    &.vjs-show {
      transform: translateX(0);
    }
    .vjs-policy--container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      i {
        font-size: 4rem;
        padding-left: 2.25rem;
      }
      div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-right: 0;
        padding-left: 1.875rem;
        font-size: 1rem;
        line-height: 1.2rem;
        .vjs-warning {
          font-size: 1.25rem;
          font-weight: bolder;
        }
        p {
          text-align: left;
        }
      }
    }
  }
  .vjs-menu-item {
    &::first-letter {
      text-transform: uppercase;
    }

    &[aria-checked='true'] {
      .vjs-menu-item-text {
        font-family: 'FontAwesome';
        &:after {
          margin-left: 15%;
          content: '\f00c';
          font-weight: 900;
          color: #2b333f;
        }
      }
    }
  }
  .vjs-menu-button-popup .vjs-menu {
    left: -7em;
  }
  .video-js .vjs-top-navigation {
    top: 2%;
    left: 2%;
    position: absolute;
    display: none;
    z-index: 9999;
  }

  .video-js.vjs-user-active,
  .video-js.vjs-paused {
    .vjs-top-navigation {
      display: flex;
      justify-content: center;
      align-items: center;
      .vjs-logo {
        margin-left: 10px;
        width: 50px;
        height: 50px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .vjs-report,
    .vjs-btn-share {
      display: flex;
    }
  }
  .vjs-button > .vjs-icon-placeholder:before {
    position: relative;
  }
  .video-js .vjs-volume-panel.vjs-volume-panel-vertical {
    width: auto;
    position: relative;
    padding: 0;
  }
  .video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical {
    position: absolute !important;
    transition: none !important;
    left: 0 !important;
    bottom: 100% !important;
  }
  .video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical {
    // bottom: -100%;
    z-index: -1;
  }
  .video-js .vjs-volume-panel .vjs-mute-control:hover ~ .vjs-volume-control.vjs-volume-vertical,
  .video-js .vjs-volume-panel .vjs-volume-control.vjs-slider-active.vjs-volume-vertical,
  .video-js .vjs-volume-panel .vjs-volume-control:active.vjs-volume-vertical,
  .video-js .vjs-volume-panel .vjs-volume-control:hover.vjs-volume-vertical,
  .video-js .vjs-volume-panel:active .vjs-volume-control.vjs-volume-vertical,
  .video-js .vjs-volume-panel:focus .vjs-volume-control.vjs-volume-vertical,
  .video-js .vjs-volume-panel:hover .vjs-volume-control.vjs-volume-vertical {
    left: 0;
    bottom: 100%;
    z-index: 2;
  }
  .vjs-control {
    display: flex;
  }
  .video-js {
    .vjs-control {
      // display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      height: auto;
      padding: 0.5em;
      font-size: 1rem;
      .vjs-control {
        padding: 0;
      }
    }
    .vjs {
      &-list-eps {
        overflow: visible;
        // padding: 0;
        .dropup {
          overflow: visible;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.25rem 1rem;
          border-radius: 5px;
          background: #fff;
          ion-icon {
            font-size: 16px;
            padding-right: 0.5rem;
          }
          &.open {
            ion-icon,
            span.ion-title {
              color: #2574d4;
            }
          }
        }
        .dropdown-menu {
          background: transparent;
          width: 200px;
          padding: 0 1rem;
          margin-bottom: 35px;
          margin-left: -20px;
          height: 0;
          padding: 0;
          // overflow-y: scroll;
          .linkEps {
            border: none;
            border-radius: 4px;
            background: #1c1b1b;
            position: absolute;
            min-width: 100%;
            max-height: 30vh;
            overflow-y: scroll;
            padding: 0 1rem;
            bottom: 100%;
            .notVip {
              display: none;
            }
            .isVip {
              color: #e8b600;
              font-weight: 400;
              font-size: 0.85rem;
              text-transform: uppercase;
              display: flex;
              i {
                display: block;
                padding-right: 0.2rem;
              }
            }
            &::-webkit-scrollbar {
              width: 5px;
            }

            &::-webkit-scrollbar-track {
              box-shadow: inset 0 0 6px #353535;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #676767;
              // outline: 1px solid slategrey;
              border-radius: 10px;
            }
            button {
              border-bottom: 1px solid #545454;
              width: 100%;
              padding: 1.5rem 0;
              color: #ffffff;
              &:hover {
                text-shadow: 0 0 1em #fff;
                text-decoration: none;
              }
              &.active {
                text-shadow: 0 0 1em #fff;
                i {
                  display: block;
                }
              }
              i {
                display: none;
              }
            }
            button:last-child {
              border-bottom: none;
            }
          }
        }
      }
      &-seek-next,
      &-next {
        > .fa::before {
          font-size: 1em;
        }
      }
      &-seek-prev,
      &-prev {
        > .fa::before {
          font-size: 1em;
        }
      }
      &-icon-placeholder::before {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-title-control-bar {
        flex: 1 1;
        text-align: left;
        padding: 0 1em;
        justify-content: flex-start;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
      }
    }
  }
  /* The "Big Play Button" is the play button that shows before the video plays.
   To center it set the align values to center and middle. The typical location
   of the button is the center, but there is trend towards moving it to a corner
   where it gets out of the way of valuable content in the poster image.*/

  .vjs-default-skin {
    .vjs-big-play-button,
    .vjs-big-pause-button {
      font-size: 4em;
      /* We're using SCSS vars here because the values are used in multiple places.
      Now that font size is set, the following em values will be a multiple of the
      new font size. If the font-size is 3em (30px), then setting any of
      the following values to 3em would equal 30px. 3 * font-size. */
      $big-play-width: 1.5em;
      /* 1.5em = 45px default */
      $big-play-height: 1.5em;
      line-height: $big-play-height;
      height: $big-play-height;
      width: $big-play-width;
      /* 0.06666em = 2px default */
      // border: 0.06666em solid $secondary-foreground-color;
      /* 0.3em = 9px default */
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      position: absolute;
    }
    /* The font size is what makes the big play button...big.
     All width/height values use ems, which are a multiple of the font size.
     If the .video-js font-size is 10px, then 3em equals 30px.*/
  }

  .video-js .vjs-volume-menu-button {
    .vjs-menu-content {
      &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        height: 100%;
      }
      .vjs-volume-bar {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  /* The default color of control backgrounds is mostly black but with a little
   bit of blue so it can still be seen on all-black video frames, which are common. */

  .video-js.vjs-has-started .vjs-tech {
    pointer-events: none;
  }

  .video-js.vjs-mobile .vjs-tech {
    pointer-events: auto;
  }

  .video-js .vjs-big-play-button,
  .video-js .vjs-big-pause-button {
    // display: none;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s;
    padding: 0;
  }

  .video-js.vjs-has-started.vjs-mobile:not(.vjs-waiting):not(.vjs-ended) {
    &.vjs-paused {
      .vjs-big-play-button {
        opacity: 1;
        visibility: visible;
        display: block;
      }
    }
    &.vjs-playing.vjs-user-active {
      .vjs-big-pause-button {
        opacity: 1;
        visibility: visible;
        display: block;
      }
    }
  }

  .video-js .vjs-control-bar,
  .video-js .vjs-big-play-button,
  .video-js .vjs-big-pause-button,
  .video-js .vjs-menu-button .vjs-menu-content {
    /* IE8 - has no alpha support */
    background-color: $primary-background-color;
    /* Opacity: 1.0 = 100%, 0.0 = 0% */
    background-color: rgba($primary-background-color, 0.9);
  }

  .video-js .vjs-control-bar {
    padding-top: 2em;
    background-color: rgba($primary-background-color, 0.9);
    width: 100%;
    height: auto;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    transition: visibility 0.3s, opacity 0.5s linear;

    .vjs-menu {
      z-index: 2;
      li {
        text-align: left;
        padding-left: 0.5rem;
      }
    }
  }

  .video-js .vjs-current-time {
    display: block;
    position: absolute;
    right: 0;
    top: $progress-position;
    line-height: 1;
    padding: 0 1em;
  }

  /* Slider - used for Volume bar and Progress bar */

  .video-js .vjs-slider {
    background-color: $slider-bg-color;
    background-color: rgba($slider-bg-color, 0.8);
    border-radius: 1em;
    margin: 0;
  }

  // .video-js .vjs-remaining-time {
  //   flex: 1;
  //   text-align: left;
  // }

  /* The slider bar color is used for the progress bar and the volume bar
   (the first two can be removed after a fix that's coming) */

  .video-js .vjs-volume-level,
  .video-js .vjs-play-progress,
  .video-js .vjs-slider-bar {
    background: $primary-foreground-color;
    border-radius: 1em;
  }

  .video-js .vjs-play-progress {
    color: $secondary-foreground-color;
    background: $secondary-foreground-color;
    font-size: 1.3em;
    &:before {
      transition: width 0.1s ease-out, height 0.1s ease-out;
      content: '';
      top: 50%;
      border: 0;
      background: radial-gradient($secondary-foreground-color 33%, #0095ff);
      width: 1em;
      height: 1em;
      border-radius: 50%;
      box-shadow: #000 0 0 2px;
      transform: translateY(-50%);
    }
    &:hover {
      &:before {
        width: 1.1em;
        height: 1.1em;
        border: 2px solid transparent;
      }
    }
  }

  .video-js .vjs-progress-control {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0 5em 0 0.4em;
    height: 1em;
    top: $progress-position;
    border-radius: 1em;
    transition: top 150ms linear, opacity 150ms linear, transform 150ms linear,
      -webkit-transform 150ms linear, -moz-transform 150ms linear, -o-transform 150ms linear;
    z-index: 1;
    // opacity: 0;
    .vjs-marker {
      position: absolute;
      opacity: 1;
      height: 99%;
      background-color: #ffe100;
      width: 2px;
    }
    &:hover {
      .vjs-progress-holder {
        font-size: inherit;
        // border-radius: 0px;
      }
      .vjs-marker {
        &-end {
          width: 2px;
          height: 30%;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 0.25rem;
        }
        width: 5px;
      }
    }
    .vjs-mouse-display {
      background: $primary-foreground-color;
      &:before {
        top: 100%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        border-top-color: #262626;
        border-width: 0.8em;
        // right: 25%;
        margin-left: -0.8em;
      }
    }
  }

  .video-js .vjs-time-tooltip {
    background: $primary-foreground-color;
    color: $secondary-foreground-color;
    &:before {
      top: 100%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      border-top-color: #262626;
      border-width: 0.8em;
      right: 25%;
      margin-left: -0.8em;
    }
  }

  .video-js .vjs-progress-holder:hover {
    height: 100%;
  }

  /* The main progress bar also has a bar that shows how much has been loaded. */

  .video-js .vjs-load-progress {
    /* For IE8 we'll lighten the color */
    // background: lighten($slider-bg-color, 5%);
    /* Otherwise we'll rely on stacked opacities */
    // background: rgba($slider-bg-color, 0.5);
    background: rgba(0, 149, 255, 0.8);
    //box-shadow: 0 0.2em 0.1em 0 #000;
    border-radius: 1em;
    // height: 0.9em !important;
  }

  /* The load progress bar also has internal divs that represent
   smaller disconnected loaded time ranges */

  .video-js .vjs-load-progress div {
    /* For IE8 we'll lighten the color */
    background: lighten($slider-bg-color, 5%);
    /* Otherwise we'll rely on stacked opacities */
    background: rgba($slider-bg-color, 0.75);
    // border-radius-right: 1em;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
    // height: 0.9em !important;
  }

  .vjs-loading-spinner {
    border: none;
    opacity: 0;
    visibility: hidden;
    animation: vjs-spinner-fade-out 2s linear 1;
    animation-delay: 2s;
    &:before,
    &:after {
      border: none;
    }
    &:after {
      background-image: url(../../../assets/img/site-spinner-blue.png);
      background-repeat: no-repeat;
      background-position-x: 50%;
      background-position-y: 50%;
      -moz-background-size: 100%;
      -o-background-size: 100%;
      background-size: 100%;
    }
  }

  .vjs-seeking .vjs-loading-spinner:after,
  .vjs-waiting .vjs-loading-spinner:after {
    animation: vjs-spinner-spin 1.1s linear infinite, vjs-spinner-fade 1.1s linear 1 !important;
    animation-delay: 2s;
  }

  .vjs-seeking .vjs-loading-spinner,
  .vjs-waiting .vjs-loading-spinner {
    opacity: 1;
    visibility: visible;
    animation: vjs-spinner-fade-in 2s linear 1;
    animation-delay: 2s;
  }
  //
  .vjs-episode {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1rem;
    text-align: center;
    $padding: 1em;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.25s;
    &-next {
      right: $padding;
    }
    &-prev {
      left: $padding;
    }
    button {
      background-color: #fff;
      border-radius: 50%;
      width: 2.5em;
      height: 2.5em;
      font-size: 1.5em;
      margin-bottom: 0.5em;
      transition: all 0.25s;
      .fa {
        color: #2574d4;
        transition: all 0.25s;
      }
      &:hover {
        background-color: #2574d4;
        .fa {
          color: #fff;
        }
      }
    }
    p {
      text-decoration: none solid rgb(255, 255, 255);
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.7);
      margin-bottom: 0.3em;
    }
    &--text {
      font-size: 1.125em;
      color: #ffffff;
      text-transform: capitalize;
    }
    &--title {
      font-size: 0.9em;
      color: #94a3ad;
      font-style: italic;
      max-width: 8em;
      text-transform: capitalize;
    }
  }

  .vjs-top-title {
    position: absolute;
    font-size: 1rem;
    top: 2em;
    right: 1.5em;
    left: 1.5em;
    text-align: right;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.25s;
    &--title {
      text-transform: uppercase;
      color: #bfbfbf;
      font-size: 1.5em;
      font-weight: bold;
    }
    &--info {
      font-size: 1em;
      color: #bfbfbf;
    }
  }
  .video-js .vjs-play-button-request {
    align-items: center;
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    z-index: 99;
    cursor: pointer;
    transition: all 0.25s;
    padding: 0;
    span {
      opacity: 0;
      visibility: hidden;
      font-size: 5rem;
    }
  }
  .vjs-has-started.vjs-episodes {
    &.vjs-user-active,
    &.vjs-paused {
      .vjs-episode:not(.vjs-episodes--hide),
      .vjs-top-title {
        visibility: visible;
        pointer-events: auto;
        opacity: 1;
      }
    }
  }
  .vjs-episodes {
    .vjs-episodes--hide {
      display: none;
    }
  }
  .vjs-error {
    .vjs-tech,
    video,
    .vjs-progress-control {
      display: none;
    }
    &.vjs-episodes .vjs-control-bar {
      display: flex !important;
    }
  }

  // next

  // prev

  @keyframes vjs-spinner-fade-in {
    0% {
      opacity: 0;
      visibility: visible;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }

  @keyframes vjs-spinner-fade-out {
    0% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: visible;
    }
  }

  .vjs-volume-control {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  // circle

  $dasharray: 189;
  @keyframes circletimer {
    0% {
      stroke-dashoffset: $dasharray;
      stroke-dasharray: $dasharray;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: $dasharray;
    }
  }
  .vjs-auto-next {
    background: black;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .vjs-auto-next-spiner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &--svg {
      font-size: 1rem;
      width: 70px;
      height: 70px;
      background-color: transparent;
      background-color: transparent;
      transform: rotateZ(-90deg);

      .circle {
        stroke: $secondary-foreground-color;
        stroke-width: 5;
        fill: rgba(0, 0, 0, 0.5);
        stroke-dashoffset: $dasharray;
        stroke-dasharray: 0;
      }
    }

    .timer {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 1.75em;
      font-weight: 700;
    }
  }
  .vjs-auto-next-cancel {
    top: 63%;
  }
  .vjs-curated-live {
    .vjs-auto-next-cancel {
      top: 57%;
    }
  }

  .vjs-auto-next-spiner {
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s;
    pointer-events: none;
  }
  .vjs-ended.vjs-episodes:not(.vjs-episode-last):not(.vjs-ad-playing):not(.vjs-waiting):not(.vjs-seeking),
  .vjs-ended.vjs-rewatching:not(.vjs-button-nextepg-open) {
    .vjs-auto-next-spiner {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      .circle {
        animation: 15s circletimer linear;
      }
    }
  }

  .video-js {
    &:not(.vjs-live):not(.vjs-liveui):not(.vjs-rewatching) {
      .vjs-epg-button,
      .vjs-top-policy {
        display: none !important;
      }
    }
  }

  .vjs-epg-display {
    position: absolute;
    width: 80vmin;
    height: calc(100% - 96px);
    top: 0;
    right: -100vmin;
    transition: right 0.5s;
    z-index: 999;
  }
  .vjs-tech,
  .vjs-control-bar {
    transition: width 0.5s !important;
  }
  .vjs-control-bar {
    z-index: 9;
    .projectw__liveui {
      pointer-events: unset !important;
    }
    &.pending__controlbar {
      display: none;
    }
  }
  .vjs-error:not(.vjs-rewatching),
  .video-js:not(.vjs-live):not(.vjs-liveui):not(.vjs-rewatching) {
    .vjs-epg-display {
      display: none !important;
    }
  }

  .player-wrapper {
    display: flex;
    background-color: #000;
    overflow: hidden;
    position: relative;
    &.vjs-epg-open {
      .vjs-epg-display {
        right: 0;
      }
    }
    .vjs-button {
      transition: background-color 0.25s;
    }
  }
  .vjs-black-out {
    // .vjs-epg {
    //   display: none !important;
    // }
    .vjs-control-bar {
      background-color: transparent;
    }
    .vjs-top-policy,
    video,
    .vjs-control-bar [class*='vjs-'] {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
    [class^='vjs-black-out'],
    [class^='video-js'],
    [class^='vjs-top-navigation'] {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
    .vjs-black-out-display {
      display: block;
    }
    .vjs-control-bar,
    .vjs-epg-button,
    .vjs-epg-display {
      opacity: 1 !important;
      visibility: visible !important;
      pointer-events: auto;
      z-index: 1000;
      .vjs-epg-button {
        [class*='vjs-'] {
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
        }
      }
    }
  }
  .vjs-black-out-display {
    position: absolute;
    z-index: 99;
    max-height: 100%;
    // height: 0;
    height: 100%;
    width: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    padding: 1em 1em;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    overflow-y: auto;
    background-color: #1c1c1c;
    font-size: 1rem;
    hr {
      width: 100%;
      &:last-of-type {
        display: none;
      }
    }
    .vjs-black-out-announcement {
      &--title {
        text-align: center;
        color: #fff;
        font-size: 2.25em;
        margin-bottom: 0.5em;
      }
      &--txt {
        color: #999999;
        font-size: 1.125em;
        text-align: center;
        line-height: 1.3;
      }
      &--logo-wrap {
        text-align: center;
        margin-bottom: 2em;
      }
      &--logo {
        width: 242px;
      }
    }
  }
}

@media (min-width: 992px) {
  body {
    .vjs-epg-display {
      height: 100%;
    }
    .player-wrapper {
      .vjs-epg-display {
        max-width: 30%;
      }

      &.vjs-epg-open {
        .vjs-tech,
        .vjs-control-bar {
          width: 70%;
        }
        .vjs-epg-display {
          width: 30%;
        }
      }
    }
    .video-js {
      .vjs-play-button-request {
        span {
          opacity: 1;
          visibility: unset;
        }
      }
      &.vjs-epg-open {
        .vjs-show,
        .vjs-report {
          right: 30%;
        }
        .vjs-btn-share {
          right: calc(32% + 2rem);
        }
        .v-cooldown {
          right: 30%;
          width: calc(100% - 30%);
        }
      }
      &.vjs-policy-open {
        .vjs-report,
        .vjs-btn-share {
          display: none;
        }
      }
      .vjs-auto-next-cancel {
        top: 60%;
      }
    }
    .vjs-black-out-display {
      padding: 2em 20%;
    }
    .video-js {
      .vjs-epg-button {
        &--text {
          display: inline;
        }
        &--fa {
          display: none;
        }
      }
      .vjs {
        &-list-eps {
          .dropup {
            ion-icon {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}

@media screen and (orientation: portrait) and (max-width: 767px) {
  body {
    .video-js .vjs-text-track-display > div > div {
      font-size: 1em !important;
      height: fit-content !important;
    }
  }
}
@media screen and (max-width: 767px) {
  body {
    .video-js {
      .vjs-big-play-button,
      .vjs-big-pause-button {
        transform: translate(-50%, -50%) scale(0.5);
      }
    }
    .vjs {
      &-list-eps {
        &:hover {
          ion-icon,
          span.ion-title {
            color: #2574d4;
          }
        }
      }
    }
    .video-js:not(.vjs-fullscreen):not(.vjs--projectw) {
      &.vjs-has-started .vjs-text-track-display {
        bottom: 0 !important;
        > div > div {
          transform: translateY(-100%) !important;
          top: calc(100% - var(--cb)) !important;
        }
      }
      // &.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
      //   visibility: visible;
      //   opacity: 1;
      // }
      // .vjs-top-navigation {
      //   display: inline-block;
      // }
    }
  }
}

@media (min-width: 576px) {
  body {
    .video-js {
      .vjs-title-control-bar {
        overflow: hidden;
        display: -webkit-inline-box;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        line-height: 1.25rem;
        padding: 0.75rem;
      }
      .vjs-fullscreen-control {
        position: relative;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  body {
    .vjs-button > .vjs-icon-placeholder:before {
      font-size: 1.8em;
    }

    .vjs {
      &-seek-next,
      &-next {
        > .fa::before {
          font-size: 1em;
        }
      }
      &-seek-prev,
      &-prev {
        > .fa::before {
          font-size: 1em;
        }
      }
    }
    .video-js {
      .vjs-control {
        padding: 0.75em;
      }
      .vjs-top-policy {
        width: 55vw;
        .vjs-policy--container {
          div {
            padding-right: 5.8125rem;
          }
        }
      }
      .vjs-list-eps .dropdown-menu {
        width: 270px;
      }
      .vjs-mouse-display {
        .vjs-scubber-thumbnail {
          width: 200px;
        }
      }
    }

    .video-js .vjs-progress-control {
      padding: 0 5em 0 0.4em;
    }
    .video-js .vjs-current-time {
      padding: 0 1em;
    }
    .vjs-top-title,
    .vjs-episode {
      font-size: 1rem;
    }
  }
}

@media screen and (min-width: 992px) {
  body {
    .vjs-black-out {
      .vjs-black-out-display {
        display: flex;
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  body {
    .video-js {
      .vjs-top-policy {
        width: 40vw;
      }
    }
    #boxplayerWrapper {
      .vjs-ads-blocker {
        &--container {
          .col-xlg {
            width: 30rem;
          }
        }
      }
    }
  }
}

@media (orientation: landscape) and (max-width: 1024px) {
  body {
    .vjs-epg-display {
      height: calc(100% - 50px);
    }
    .vjs-black-out {
      .vjs-black-out-display {
        display: block;
      }
    }
    .video-js {
      .vjs-control {
        padding: 10px;
      }
      .vjs-control-bar {
        .vjs-button > .vjs-icon-placeholder:before,
        .dropup ion-icon {
          font-size: 1.2em;
        }
        i.vjs-icon-placeholder:before {
          font-size: 1em !important;
        }
        .vjs-title-control-bar,
        .vjs-remaining-time-display,
        .vjs-volume-panel,
        .vjs-current-time-display {
          font-size: 0.825rem;
        }
      }
      .vjs {
        &-list-eps {
          .dropup {
            padding-left: 0.5rem;
            padding-right: 0;
          }
          span.ion-title {
            display: none;
          }
        }
      }
    }
  }
}
