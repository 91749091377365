.v-stripe {
  form {
    [class*='col'] {
      .row {
        margin-right: -0.5rem;
        margin-left: -0.5rem;
        [class*='col'] {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }
      }
    }
  }
  .paypal {
    &--loading {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .error {
    color: #c23d4b;
    margin: 5px 0;
    font-size: 16px;
  }
  label {
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
    color: #283237;
    font-weight: 300;
    letter-spacing: 0;
    margin: 0;
  }
  .v-input.StripeElement {
    display: block;
    margin: 0;
    width: 100%;
    font-size: 1rem;
    border-radius: 8px;
    background: #fefefe;
    color: #fff;
    border: 1px solid #b3bec4;
    padding: 1rem;
    cursor: pointer;
  }
  .StripeElement input.InputElement,
  input.ElementsApp {
    color: #ffffff;
    font-size: 30px;
    background: #fefefe;
  }
  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
  .StripeElement.IdealBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }
}
@media (min-width: 768px) {
}
