.v-popupContactUsSucess {
  &.modal {
    top: 5%;
  }
  .modal-body {
    p {
      color: #696969;
      font-style: Italic;
      font-size: 18;
      font-weight: 100;
      padding: 1rem 9rem;
    }
  }
}

.v-popupContactUs {
  &.modal {
    top: 5%;
  }
  .modal-dialog {
    width: auto;
    .modal-header {
      border: none;
      padding-bottom: 0;
    }
    .modal-body {
      padding-top: 0px;
      .headerPopup {
        padding: 0;
        text-align: center;
        .title {
          font-size: 1.5rem;
          color: #283237;
          font-weight: bolder;
          margin-top: 0;
          padding: 0 1rem;
          line-height: 2rem;
        }
        .des {
          margin: 2rem 0;
          color: #596d79;
          font-size: 1.025rem;
        }
        .phone {
          font-size: 1.5rem;
          font-weight: 100;
          color: #495963;
        }
        p:last-child {
          margin: 2.5rem 0;
          text-align: center;
          font-weight: 400;
          text-transform: uppercase;
          position: relative;
          &:before {
            position: absolute;
            content: '';
            height: 1px;
            border: 1px solid #e6ecef;
            width: 40%;
            top: 50%;
            left: 0;
          }
          &:after {
            position: absolute;
            content: '';
            height: 1px;
            border: 1px solid #e6ecef;
            width: 40%;
            top: 50%;
            right: 0;
          }
        }
      }
      .formContacUs {
        padding: 0;
        .form-group {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .v-group {
            width: 100%;
          }
        }
        .btnContacUs {
          background: #2574d4;
          color: #fff;
          width: 100%;
          padding: 0.75rem 0;
          font-weight: 600;
          margin-top: 4rem;
        }
        .loadding {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .v-popupContactUs {
    &.modal {
      top: 15%;
    }
    .modal-dialog {
      width: 35.625rem;
      .modal-body {
        .headerPopup {
          padding: 0 6rem;
        }
        .formContacUs {
          padding: 0 6rem;
        }
      }
    }
  }
}
